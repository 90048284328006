import { Component, OnDestroy, OnInit, Renderer2, ViewChild, inject } from '@angular/core';
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { SolicitudMedicamentosService } from '../services/solicitud-medicamentos.service';
import { SolicitudMedicamentosSettings } from '../config/solicitud-medicamentos.config';
import { ProcessType, SPlusDialogCloseResult, SPlusDialogSettings, SPlusMoreOption, SPlusOption } from '@saludplus/forms';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AddEvent, CancelEvent, CellClickEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from "@progress/kendo-data-query";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ArticulosSolicitudesDTO, SolicitudMedicamentosDTO } from '@src/app/models/Asistencial/SolicitudMedicamentos/SolicitudMedicamentos';
import { SearchAdmissionsV2Service } from '@src/app/Components/Generales/Admisiones/search-admissionsV2/services/search-admissionsV2.service';
import { AdmisionesDTO } from '@src/app/models/General/AdmisionesDTO';
import { CaracteristicasModule } from '@src/app/models/General/Permisos';
import { FiltersData } from '@src/app/models/base/filterModel';
import { HttpErrorResponse } from '@angular/common/http';
import format from 'date-fns/format';

@Component({
  selector: 'app-solicitud-medicamentos',
  templateUrl: './solicitud-medicamentos.component.html',
  styleUrls: ['./solicitud-medicamentos.component.css']
})
export class SolicitudMedicamentosComponent extends SplusBaseForm implements OnInit, OnDestroy {

  filtersData = [
    {
      propertyName: "idCaracteristica",
      propertyValue: CaracteristicasModule.SolicitudMedicamentos,
    },
  ] as FiltersData[];
  filtersDataSuministros = [
    {
      propertyName: "activo",
      propertyValue: true,
    },
    {
      propertyName: "fkGrupo",
      propertyValue: 2,
    },
  ] as FiltersData[];
  DatosRecord: SolicitudMedicamentosDTO;
  dataAdmisionTocontrol: AdmisionesDTO;
  private unsubcribe$ = new Subject<void>();
  private service = inject(SolicitudMedicamentosService);
  private settings = inject(SolicitudMedicamentosSettings);
  private renderer = inject(Renderer2);
  private searchAdmissionsService = inject(SearchAdmissionsV2Service);

  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Listado",
      icon: "fa-solid fa-magnifying-glass",
      text: "Listado",
      link: "/asistencial/solicitud-articulos/listado",
    },
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];

  //listado de más opciones --
  moreOptions: SPlusMoreOption[] = [
    {
      id: "CrearSolicitud",
      showIn: ProcessType.view,
      text: "Crear nueva solicitud",
      icon: "fa-duotone fa-plus",
      action: () => this.newRecord(),
    },
    {
      id: "EliminarSolicitud",
      showIn: ProcessType.edit,
      text: "Eliminar",
      icon: "fa-solid fa-trash-can",
      action: () => this.dialogEliminar(),
    },
  ];


  // Grilla Medicamentos
  @ViewChild("gridMedicamentos") public gridRefMedicamentos: GridComponent;
  public gridDataMedicamentos: GridDataResult;
  dataGridMedicamentos: any[] = [];
  editedRowIndexMedicamentos = 0;
  formGroupMedicamentos: FormGroup;
  isDeleteMedicamentos = false;
  saveEnterMedicamentos = false;
  public gridStateMedicamentos: State = {
    sort: [],
    skip: 0,
    take: 10,
  };
  // Grilla Suministros
  @ViewChild("gridSuministros") public gridRefSuministros: GridComponent;
  public gridDataSuministros: GridDataResult;
  dataGridSuministros: any[] = [];
  editedRowIndexSuministros = 0;
  formGroupSuministros: FormGroup;
  isDeleteSuministros = false;
  saveEnterSuministros = false;
  isNew = false;
  invalid = false;
  public gridStateSuministros: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  private docClickSubscription: Subscription = new Subscription();
  constructor() {
    super()
  }

  ngOnInit() {

    this.id = this.route.snapshot.params["id"];
    this.title = this.route.snapshot.data["title"];
    this.icon = this.route.snapshot.data["icon"];
    this.titleService.setTitle(this.title);

    this.FormRecord = this.settings.FormComponents();
    this.isLoading = false;

    this.docClickSubscription.add(
      this.renderer.listen("document", "click", this.onDocumentClick.bind(this))
    );

    if (this.id) {
      this.LoadRecord();
    }
  }

  ngOnDestroy(): void {
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }

  /**
   * Abreviacion para acceder a los controles del FormRecord
   */
  public get f(): any {
    return this.FormRecord.controls;
  }
  override onSubmit(event: any): void {
    try {
      event.preventDefault();
      this.submitted = true;
      if (this.FormRecord.valid) {
        if (this.FormRecord.pristine) return;
        this.isLoading = true;
        if (this.processType === this.ProcessType.edit) {
          this.UpdateRecord();
        } else {
          this.SaveRecord();
        }
      } else {
        this.isLoading = false;
      }
    } catch (error) {
      this.isLoading = false;
    }

  }
  override SaveRecord(): void {
    let data = this.FormRecord.value as SolicitudMedicamentosDTO;
    data.hora = format(data.hora, 'HH:mm');
    data.medicamentos = this.dataGridMedicamentos;
    data.suministrosMedicos = this.dataGridSuministros.map(s => {
      let map = {
        idArticulo: s?.articulo?.id,
        cantidad: s?.cantidad
      } as ArticulosSolicitudesDTO
      return map;
    });

    this.service
      .Guardar<number, SolicitudMedicamentosDTO>(data)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([`${res}`], {
              queryParams: { mode: "i" },
              relativeTo: this.route,
            });
          }

        }
      });
  }
  override UpdateRecord(): void {
    let data = this.FormRecord.value as SolicitudMedicamentosDTO;
    data.hora = format(data.hora, 'HH:mm');
    data.medicamentos = this.dataGridMedicamentos;
    data.suministrosMedicos = this.dataGridSuministros.map(s => {
      let map = {
        id: s.id,
        idArticulo: s?.articulo?.id,
        cantidad: s?.cantidad
      } as ArticulosSolicitudesDTO
      return map;
    });
    data.id = this.id;
    this.service
    .Actualizar<boolean, SolicitudMedicamentosDTO>(data)
    .subscribe({
      next: (res) => {
        this.isLoading = false;
        if (res) {
          this.router.navigate([], {
            queryParams: { mode: "u" },
            queryParamsHandling: "merge",
          });
        }


      }
    });
  }
  override DeleteRecord(): void {
    this.isDeleting = true;
    this.isLoading = true;
    const userSearchParams = {
      idSolicitud: this.id
    };
    this.service.Eliminar<boolean>(userSearchParams).subscribe({
      next: (res) => {
        this.isLoading = false;
        if (res) {

          this.isDeleting = false;
          this.router.navigate(["../"], {
            queryParams: { mode: "d" },
            queryParamsHandling: "merge",
            relativeTo: this.route,
          });
        }

      }
    });
  }
  override LoadRecord() {

    this.isLoading = true;
    const userSearchParams = {
      idSolicitud: this.id
    };
    this.service.Buscar<SolicitudMedicamentosDTO>(userSearchParams).subscribe({
      next: async (res) => {

        this.isDeleting = false;
        if (res) {
          let admisiones = await this.searchAdmissionsService.FiltroAdmisionesAsync<AdmisionesDTO>(res.idAdmision);
          if (admisiones.isSuccessful) {
            this.FormRecord.get("admision").setValue(admisiones.result);
            this.dataAdmisionTocontrol = admisiones.result
          }


          this.CargarDatosOriginales(res)
          this.DatosRecord = JSON.parse(JSON.stringify(res));
          this.isLoading = false;


        }
      }
    });
  }
  isDate(value: any): boolean {
    return value instanceof Date && !isNaN(value.getTime());
}
  CargarDatosOriginales(res: SolicitudMedicamentosDTO) {
    if(!this.isDate(res.fecha)){
      res.fecha = this.ToDate(res.fecha);
      res.hora = this.SetHours(res.fecha, res.hora);
    }


    this.FormRecord.patchValue(res);
    this.dataGridMedicamentos = res.medicamentos;
    this.dataGridSuministros = res.suministrosMedicos;
    this.gridDataMedicamentos = process(this.dataGridMedicamentos, this.gridStateMedicamentos);
    this.gridDataSuministros = process(this.dataGridSuministros, this.gridStateSuministros);
    this.DatosRecord = res;
  }
  /**
   * formulario en modo edicción
   */
  goEdit() {
    this.routingState.goEdit();
  }
  override cancelEdit() {
    if (this.processType === ProcessType.create) {
      this.goback();
    } else if (this.processType === ProcessType.edit) {
      // validar si se modificó el formulario visualmente
      if (!this.FormRecord.pristine) {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cancelar edición";
        dialogSettings.isHtml = true;
        dialogSettings.description =
          "Hay cambios pendientes por guardar. <br/>¿Estás seguro que desea cancelar la edición?";
        dialogSettings.actions = [
          { text: "Seguir Editando" },
          { text: "Si, Cancelar", themeColor: "primary", value: true },
        ];
        this.splusFormsDialogsService
          .Show(dialogSettings)
          .pipe(takeUntil(this.unsubcribe$))
          .subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult) {
              console.log("close", result);
            } else {
              if (result.value) {
                this.cancelUpdate();
              }
            }
          });
      } else {
        this.goback();
      }
    }
  }

  override clearForm(): void {
    this.FormRecord = this.settings.FormComponents();
    this.FormRecord.updateValueAndValidity();
    this.dataGridMedicamentos = [];
    this.dataGridSuministros = [];
    this.gridDataMedicamentos = process(this.dataGridMedicamentos, this.gridStateMedicamentos);
    this.gridDataSuministros = process(this.dataGridSuministros, this.gridStateSuministros);

    this.submitted = false;
  }
  cancelUpdate() {

    this.DatosRecord.fecha = new Date(this.DatosRecord.fecha);
this.DatosRecord.hora = new Date(this.DatosRecord.hora);
    this.CargarDatosOriginales(this.DatosRecord)

    this.FormRecord.markAsPristine();
    this.router.navigate([], {
      queryParams: { mode: null },
      queryParamsHandling: "merge",
    });
  }
  async GetDataAdmision(data: AdmisionesDTO) {
    this.FormRecord.get("idAdmision").setValue(data.id);
    this.isLoading = true;
    if (ProcessType.create === this.processType) {

      this.dataGridMedicamentos = await this.service.ObtenerMedicamentosAsync<ArticulosSolicitudesDTO[]>(data.id);
      this.gridDataMedicamentos = process(this.dataGridMedicamentos, this.gridStateMedicamentos);
      if (this.dataGridMedicamentos) {

        this.isLoading = false;
      }

    } else {
      this.isLoading = false;
    }



  }
  changeArticulo(data) {

  }

  /**
   * Abre alerta para eliminar registro
   */
  dialogEliminar() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Eliminar Solicitud";
    dialogSettings.description =
      "¿Estas seguro que desea eliminar esta solicitud?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Si, Eliminar", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
          console.log("close", result);
        } else {
          if (result.value) {
            this.DeleteRecord();
          }
        }
      });
  }


  //#region Grilla Medicamentos

  private closeEditorMedicamentos(
    rowIndex: number = this.editedRowIndexMedicamentos
  ): void {
    if (!this.gridRefMedicamentos) return;
    if (this.gridRefMedicamentos) this.gridRefMedicamentos.closeRow(rowIndex);
    this.editedRowIndexMedicamentos = undefined;
    this.formGroupMedicamentos = undefined;
  }
  public cellClickHandlerMedicamentos(args: CellClickEvent): void {
    if (args.isEdited) return;
    if (this.processType == ProcessType.view) return;
    if (this.formGroupMedicamentos && this.formGroupMedicamentos.valid) this.saveCurrentMedicamentos();
    if (!this.gridRefMedicamentos) return;
    if (this.isDeleteMedicamentos) {
      this.isDeleteMedicamentos = false;
      return;
    }

    if (this.formGroupMedicamentos && !this.formGroupMedicamentos.valid && args.isEdited) {
      return;
    }
    if (this.formGroupMedicamentos && this.formGroupMedicamentos.valid && this.saveEnterMedicamentos) {
      this.saveCurrentMedicamentos();
    }
    if (!args.isEdited) {
      this.closeEditorMedicamentos();
      this.createFormGroupMedicamentos(args.dataItem);
      this.gridRefMedicamentos.editCell(args.rowIndex, args.columnIndex, this.formGroupMedicamentos);
      this.editedRowIndexMedicamentos = args.rowIndex;

    }
  }
  public saveCurrentMedicamentos(): void {
    if (this.formGroupMedicamentos && !this.formGroupMedicamentos.valid) {
      return;
    }
    this.saveMedicamentos(this.formGroupMedicamentos.getRawValue());
  }

  public saveMedicamentos(product: any): void {
    if (product?.articulo) {
      this.FormRecord.markAsDirty();
      this.FormRecord.updateValueAndValidity();
      this.formGroupMedicamentos.markAsDirty();
      this.formGroupMedicamentos.updateValueAndValidity();
      const data = this.dataGridMedicamentos[this.editedRowIndexMedicamentos];
      Object.assign(data, product);
      this.closeEditorMedicamentos();
    }

  }

  public createFormGroupMedicamentos(dataItem: any) {
    this.formGroupMedicamentos = new FormGroup({
      id: new FormControl(dataItem.id ? dataItem.id : 0),
      articulo: new FormControl(dataItem.articulo ? dataItem.articulo : null),
      cantidad: new FormControl(dataItem.cantidad ? dataItem.cantidad : null, [Validators.required, Validators.max(dataItem.cantidadPermitida)]),
    });
  }
  public removeHandlerMedicamento(args: any): void {
    this.FormRecord.markAsDirty();
    this.FormRecord.updateValueAndValidity();
    this.isDeleteMedicamentos = true;
    this.editedRowIndexMedicamentos = args.rowIndex;
    this.removeMedicamentos();

  }
  public removeMedicamentos(): void {
    this.dataGridMedicamentos.splice(this.editedRowIndexMedicamentos, 1);
    this.gridDataMedicamentos = process(this.dataGridMedicamentos, this.gridStateMedicamentos);
    this.closeEditorMedicamentos();
  }
  public cancelHandlerMedicamentos(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditorMedicamentos(args.rowIndex);
  }

  pageChangeMedicamentos(data: State) {
    this.gridStateMedicamentos = data;
    this.gridDataMedicamentos = process(this?.dataGridMedicamentos, this.gridStateMedicamentos);
  }
  matches(el, selector) {
    return (el.matches || el.msMatchesSelector).call(el, selector);
  }
  private onDocumentClick(e: Event): void {

    if (!this.gridRefMedicamentos && !this.gridDataSuministros) return;
    if (
      this.formGroupMedicamentos &&
      this.formGroupMedicamentos.valid &&
      !this.matches(e.target, "#gridMedicamentos tbody *, #gridMedicamentos .k-table-td *, .info")
    ) {
      this.saveCurrentMedicamentos();
    }
    if (
      this.formGroupSuministros &&
      this.formGroupSuministros.valid &&
      !this.matches(e.target, "#gridSuministros tbody *, #gridSuministros .k-table-td *, .info")
    ) {
      this.saveCurrentSuministros();
    }
  }

  //#endregion


  //#region Suministros Grilla

  private closeEditorSuministros(
    rowIndex: number = this.editedRowIndexSuministros
  ): void {
    if (!this.gridRefSuministros) return;
    this.isNew = false;
    if (this.gridRefSuministros) this.gridRefSuministros.closeRow(rowIndex);
    this.editedRowIndexSuministros = undefined;
    this.formGroupSuministros = undefined;
  }
  public cellClickHandlerSuministros(args: CellClickEvent): void {
    if (args.isEdited) return;
    if (this.processType == ProcessType.view) return;
    if (this.formGroupSuministros && this.formGroupSuministros.valid) this.saveCurrentSuministros();
    if (!this.gridRefSuministros) return;
    if (this.isDeleteSuministros) {
      this.isDeleteSuministros = false;
      return;
    }

    if (this.formGroupSuministros && !this.formGroupSuministros.valid && args.isEdited) {
      return;
    }
    if (this.formGroupSuministros && this.formGroupSuministros.valid && this.saveEnterSuministros) {
      this.saveCurrentSuministros();
    }
    if (!args.isEdited) {
      this.closeEditorSuministros();
      this.createFormGroupSuministros(args.dataItem);
      this.gridRefSuministros.editCell(args.rowIndex, args.columnIndex, this.formGroupSuministros);
      this.editedRowIndexSuministros = args.rowIndex;

    }
  }
  public saveCurrentSuministros(): void {
    if (this.formGroupSuministros && !this.formGroupSuministros.valid) {
      return;
    }
    this.saveRow();
  }
  private saveRow(): void {
    if (this.isInEditingMode) {
      this.saveSuministros(this.formGroupSuministros.getRawValue(), this.isNew);
    }

    //this.closeEditor(this.grid);
  }
  public get isInEditingMode(): boolean {
    return this.editedRowIndexSuministros !== undefined || this.isNew;
  }
  public saveSuministros(product: any, isNew: boolean): void {
    this.FormRecord.markAsDirty();
    this.FormRecord.updateValueAndValidity();
    if (isNew) {
      //this.dataGrid.unshift(product);
      this.dataGridSuministros.unshift(product);
      this.gridDataSuministros = process(this.dataGridSuministros, this.gridStateSuministros);
    } else {
      const data = this.dataGridSuministros[this.editedRowIndexSuministros];
      Object.assign(data, product);
    }
    this.closeEditorSuministros();
  }
  public createFormGroupSuministros(dataItem: any) {
    this.formGroupSuministros = new FormGroup({
      id: new FormControl(dataItem.id ? dataItem.id : 0),
      articulo: new FormControl(dataItem.articulo ? dataItem.articulo : null),
      cantidad: new FormControl(dataItem.cantidad ? dataItem.cantidad : null, [Validators.required, Validators.min(1)]),
    });
  }

  public addHandlerSuministros(args: AddEvent): void {
    if (!this.gridRefSuministros) return;
    if (this.formGroupSuministros && this.formGroupSuministros.valid) {
      this.saveCurrentSuministros();
    }
    this.closeEditorSuministros();
    this.isNew = true;
    this.createFormGroupSuministros({
      articulo: null,
      cantidad: null,
    });
    this.gridRefSuministros.addRow(this.formGroupSuministros);
  }
  public removeHandlerSuministros(args: any): void {
    this.FormRecord.markAsDirty();
    this.FormRecord.updateValueAndValidity();
    this.isDeleteSuministros = true;
    this.editedRowIndexSuministros = args.rowIndex;
    this.removeSuministros();

  }
  public removeSuministros(): void {
    this.dataGridSuministros.splice(this.editedRowIndexSuministros, 1);
    this.gridDataSuministros = process(this.dataGridSuministros, this.gridStateSuministros);
    this.closeEditorSuministros();
  }
  public cancelHandlerSuministros(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditorSuministros(args.rowIndex);
  }
  public cancelHandler2Suministros(): void {
    this.closeEditorSuministros();
  }
  pageChangeSuministros(data: State) {
    this.gridStateSuministros = data;
    this.gridDataSuministros = process(this?.dataGridSuministros, this.gridStateSuministros);
  }
  public SaveAndAdd() {
    if (!this.gridRefSuministros) return;
    if (this.formGroupSuministros && !this.formGroupSuministros.valid) {
      return;
    }
    this.saveEnterSuministros = true;
    this.invalid = false;
    this.saveCurrentSuministros();
    if (!this.invalid) {
      this.closeEditorSuministros();
      this.isNew = true;
      this.createFormGroupSuministros({
        articulo: null,
        codigocantidadRubro: null,
      });
      this.gridRefSuministros.addRow(this.formGroupSuministros);
    }
    this.saveEnterSuministros = false;
  }

  //#endregion

}
