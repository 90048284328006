<splus-forms id="solicitudMedicamentosPage" [submit]="submitted" [messageProcess]="messageProcess"
  [showMessageProcess]="showMessageProcess" [FormRecord]="FormRecord">

  <form [formGroup]="FormRecord" (ngSubmit)="onSubmit($event)" autocomplete="off"
    [ngClass]="{'formGroup-read': processType == ProcessType.view}">
    <splus-forms-header [isLoading]="isLoading">
      <splus-forms-main-title [title]="'Solicitud Artículos'"
        [titleData]="processType == ProcessType.view ? '' : 'Solicitud Artículos'" [icon]="icon">
      </splus-forms-main-title>

      <div *ngIf="processType == ProcessType.view || processType == ProcessType.edit">
        <div class="sub-title-info ">
          {{dataAdmisionTocontrol?.nombreCompleto | uppercase }}

          <div *ngIf="isLoading">

            <div class="k-hbox">
              <kendo-skeleton shape="text" animation="pulse" width="100px"></kendo-skeleton> &nbsp;
              <kendo-skeleton shape="text" animation="pulse" width="70px"></kendo-skeleton> &nbsp;
              <kendo-skeleton shape="text" animation="pulse" width="90px"></kendo-skeleton>
            </div>
          </div>


        </div>
      </div>


      <splus-forms-toolbar [options]="options">
        <splus-forms-toolbar-buttons [moreOptions]="moreOptions" [processType]="processType">

          <button type="Submit" [disabled]="isLoading"
            *ngIf="processType == ProcessType.create || processType == ProcessType.edit" kendoButton rounded="small"
            themeColor="primary">
            <i class="fa-solid fa-floppy-disk"></i> Guardar
          </button>

          <button [disabled]="isLoading" type="button" *ngIf="processType == ProcessType.view" kendoButton
            rounded="small" themeColor="primary" (click)="goEdit()">
            <i class="fa-solid fa-pencil"></i> Editar
          </button>

          <button *ngIf="processType == ProcessType.edit" type="button" kendoButton rounded="small" themeColor="base"
            (click)="cancelEdit()">
            Cancelar
          </button>

          <button *ngIf="processType == ProcessType.view" type="button" kendoButton rounded="small" themeColor="base"
            (click)="goback()">
            Volver
          </button>

          <button *ngIf="processType == ProcessType.create" type="button" kendoButton rounded="small" themeColor="base"
            (click)="clearForm()">
            Limpiar
          </button>

        </splus-forms-toolbar-buttons>
      </splus-forms-toolbar>


    </splus-forms-header>

    <splus-forms-fields [processType]="processType">
      <splus-forms-group [title]="'Información Admision'" #grupoAdmision
        *ngIf="processType == ProcessType.create || dataAdmisionTocontrol" [mainLayout]="'layout-simple'">
        <div class="main-fields" [ngClass]="{ 'no-width': processType == ProcessType.view }"
          style="grid-template-columns: none; padding: 10px 0" *ngIf="DatosRecord || processType == ProcessType.create">
          <app-search-admissionsV2 #admisiones [filter]="filtersData" [splusFormControl]="f.admision"
            (SendDataEmitter)="GetDataAdmision($event)" [processType]="processType">
          </app-search-admissionsV2>
          <ng-template splusFieldViewModeTemplate>
            hola
          </ng-template>
        </div>
      </splus-forms-group>
      <splus-forms-group [title]="'Información Básica'" #grupoBasico>
        <splus-field *ngVar="f.fecha as ctrl" [splusFormControl]="ctrl" #fechaNacimiento>

          <kendo-datepicker #calendar splusInput [navigation]="true" [max]="maxDate" format="dd/MM/yyyy"
            [formControl]="ctrl" [splusGroup]="grupoBasico" [splusField]="fechaNacimiento" [value]="ctrl.value">

            <ng-template kendoCalendarNavigationItemTemplate let-title>
              {{isNaN(title) ? title : " Ene. " + title.substring( 2, 4 )}}
            </ng-template>

          </kendo-datepicker>

          <ng-template splusFieldViewModeTemplate>
            {{calendar.value | SPlusDate}}
          </ng-template>

          <div class="hit">
            {{!calendar.value ? '' : calendar.value | InfoDateFormat}}
          </div>


        </splus-field>
        <splus-field *ngVar="f.hora as ctrl" [splusFormControl]="ctrl">
          <kendo-timepicker splusInput [formControl]="ctrl" [id]="ctrl.nameControl"></kendo-timepicker>
          <ng-template splusFieldViewModeTemplate>
            {{ ctrl?.value  | SPlusHour}}
          </ng-template>
        </splus-field>
      </splus-forms-group>

      <splus-forms-group [title]="'Medicamentos'" #Descuentos [mainLayout]="'layout-simple'">
        <kendo-grid #gridMedicamentos id="gridMedicamentos" (cellClick)="cellClickHandlerMedicamentos($event)"
          [data]="gridDataMedicamentos" [pageSize]="gridStateMedicamentos.take" (remove)="removeHandlerMedicamento($event)"
          [skip]="gridStateMedicamentos.skip" [navigable]="true" (cancel)="cancelHandlerMedicamentos($event)"
          [pageable]="true" (pageChange)="pageChangeMedicamentos($event)">


          <kendo-grid-column field="articulo" [title]="'Medicamento'" [editable]="false">

            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem?.articulo?.nombreCompletoCompuesto}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="cantidad" title="Cantidad" [width]="100">
            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-rowIndex="rowIndex">
              <kendo-numerictextbox [spinners]="false" [formControl]="formGroup?.get('cantidad')"
               (keydown.Tab)="saveCurrentMedicamentos()" (keydown.Enter)="saveCurrentMedicamentos()">
              </kendo-numerictextbox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem?.cantidad}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-command-column title="Acciones" [width]="100" *ngIf="processType !== ProcessType.view">
            <ng-template kendoGridCellTemplate let-isNew="isNew" let-rowIndex="rowIndex">
              <button kendoGridRemoveCommand iconClass="fa-solid fa-trash-can" fillMode="flat" style="color:#c95242"
                title="Eliminar" [id]="'bt-delete'+rowIndex"></button>
              <button kendoGridCancelCommand iconClass="fa-solid fa-trash-can" fillMode="flat" style="color:#c95242">
              </button>
            </ng-template>
          </kendo-grid-command-column>
        </kendo-grid>

      </splus-forms-group>
      <splus-forms-group [title]="'Materiales e Insumos'" #Descuentos [mainLayout]="'layout-simple'">
        <kendo-grid #gridSuministros id="gridSuministros" (cellClick)="cellClickHandlerSuministros($event)" [data]="gridDataSuministros"
          (add)="addHandlerSuministros($event)" [pageSize]="gridStateSuministros.take"
          (remove)="removeHandlerSuministros($event)" [skip]="gridStateSuministros.skip" [navigable]="true" (cancel)="cancelHandlerSuministros($event)"
          [pageable]="true" (pageChange)="pageChangeSuministros($event)">
          <ng-template kendoGridToolbarTemplate>
            <button kendoGridAddCommand *ngIf="processType != ProcessType.view" rounded="small" themeColor="primary"
              icon="add" id="bt-add">Adicionar</button>

          </ng-template>

          <kendo-grid-column field="articulo" title="Articulo" >
            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup"
              let-rowIndex="rowIndex">
              <app-articulos #articulosComp [filter]="filtersDataSuministros" [splusFormControl]="formGroup.get('articulo')"
                [processType]="processType" (accountChange)="changeArticulo($event)"></app-articulos>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem?.articulo?.nombreCompletoCompuesto}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="cantidad" title="Cantidad" [width]="100">
            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-rowIndex="rowIndex">
              <kendo-numerictextbox [spinners]="false" [formControl]="formGroup?.get('cantidad')"
               (keydown.Tab)="saveCurrentSuministros()" (keydown.Enter)="SaveAndAdd()">
              </kendo-numerictextbox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem?.cantidad}}
            </ng-template>
          </kendo-grid-column>


          <kendo-grid-command-column title="Acciones" [width]="100" *ngIf="processType !== ProcessType.view">
            <ng-template kendoGridCellTemplate let-isNew="isNew" let-rowIndex="rowIndex">
              <button kendoGridRemoveCommand iconClass="fa-solid fa-trash-can" fillMode="flat" style="color:#c95242"
                title="Eliminar" [id]="'bt-delete'+rowIndex"></button>
              <button kendoGridCancelCommand iconClass="fa-solid fa-trash-can" fillMode="flat" style="color:#c95242">
              </button>
            </ng-template>
          </kendo-grid-command-column>
        </kendo-grid>

      </splus-forms-group>

    </splus-forms-fields>
  </form>

</splus-forms>
