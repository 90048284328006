import { UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, inject } from "@angular/core";
import {
  TegettFormControl,
  TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import Validation from "@src/app/components/Core/directives/generals/validatorError";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { SolicitudMedicamentosService } from "../services/solicitud-medicamentos.service";
/*
@Component({
  selector: "app-empleados-setting",
  template: ``
})*/
export class SolicitudMedicamentosSettings {


  constructor() {}


  /**
   * Componentes del formulario
   */
  FormComponents(): UntypedFormGroup {
    const formRecord = new UntypedFormGroup(
      {
        idAdmision: new TegettFormControl({
          formState: null,
          validatorOrOpts: [Validators.required],
          label: "Admisión",
          typeField: TypeField.List,
          required: true,
          nameControl: "idAdmision",
        }),
        admision: new TegettFormControl({
          formState: null,
          label: "Admisión",
          typeField: TypeField.List,
          required: true,
          nameControl: "admision",
        }),
        fecha: new TegettFormControl({
          formState: new Date(),
          validatorOrOpts: [Validators.required],
          label: "Fecha",
          typeField: TypeField.Date,
          required: true,
          nameControl: "fecha",
        }),
        hora: new TegettFormControl({
          formState: new Date(),
          validatorOrOpts: [Validators.required],
          label: "Hora",
          typeField: TypeField.Date,
          required: true,
          nameControl: "fecha",
        })
      }
    );

    return formRecord;
  }




}
