import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ControllerApiList } from '@src/app/models/SaludPlus/Controller/ControllerList';
import { SaludPlusApiResponse } from '@src/app/models/base/SaludplusApiResponse';
import { SplusBaseService } from '@src/app/services/Generals/splusBase.service';
import { Observable, lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SolicitudMedicamentosService extends SplusBaseService {
  static instance: SolicitudMedicamentosService;
  error: HttpErrorResponse;

  constructor() {
    super();
    SolicitudMedicamentosService.instance = this;
    this.nameEndPoint = `${ControllerApiList.Asistencial.SolicitudMedicamentos}`
  }


  async ObtenerMedicamentosAsync<T>(idAdmision:number): Promise<T> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("idAdmision", idAdmision);
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${this.nameEndPoint}/Medicamentos`,{params:httpParams}
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    const dataResponse = await lastValueFrom(record);
    return dataResponse;
  }


}

